.site {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;

  &__content {
    max-width: 800px;
    width: 100%;
    padding: 1rem;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    background-color: var(--light-color);
    border-radius: 1rem;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 1rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__name {
    display: block;
    font-size: 1.75rem;
    text-align: center;
  }

  &__buttons {
    display: flex;
    gap: 0.5rem;
  }

  &__btn {
    padding: 0.5rem;
  }
}
