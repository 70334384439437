.post {
  &__title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  &__descr {
    font-size: 1rem;
  }
  &__created {
    margin-top: 0.5rem;
    font-weight: 500;
  }
}
