.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__row {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  &__input {
    padding: 16px 16px;
    border: 1px solid var(--dark-color);
    border-radius: 1rem;
    font-size: 1rem;
    line-height: 1.2;
  }

  &__btn {
    margin-top: 1rem;
  }
}
