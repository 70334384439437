.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 256px;
  width: 100%;
  height: calc(100vh - 61px);
  //height: 100vh;
  background-color: var(--dark-color);
  padding: 1rem;

  &__title {
    margin: 0 0 1rem;
    font-size: 1.75rem;
    color: var(--light-color);
  }

  &__items {
  }

  &__item {
  }

  &__link {
    display: block;
    font-size: 1rem;
    color: var(--gray-color);
    padding: 0.5rem 0;

    &:focus {
      color: var(--light-color);
      border: none;
    }

    &:hover {
      color: var(--light-color);
    }
  }
}
