.feedback {
  width: 100%;
  display: flex;
  justify-content: center;

  &__content {
    max-width: 800px;
    width: 100%;
    padding: 1rem;
  }

  &__item {
    display: block;
    padding: 1rem;
    background-color: var(--gray-color);
    border-radius: 1rem;
  }

  &__list {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    padding: 1rem;
    background-color: var(--light-color);
    border-radius: 1rem;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
}
