.login {
  @include form-mixin;
  width: 100%;

  &__content {
    max-width: 520px;
    width: 100%;
    padding: 32px;
    border-radius: 12px;
    background-color: var(--light-color);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }

  &__text {
    margin-bottom: 32px;
  }

  &__title {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 500;
  }

  &__subtitle {
    font-size: 1rem;
    font-weight: 300;
  }
}
