.header {
  background-color: var(--light-color);

  &__content {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__link {
    margin-left: 1rem;
  }
}

.person {
  display: flex;
  align-items: center;

  &__name {
    font-size: 1rem;
  }

  &__logo {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: var(--dark-color);
    position: relative;
    margin-left: 0.5rem;

    & > svg {
      width: 35px;
      height: 35px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: var(--light-color);
    }
  }
}
