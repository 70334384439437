.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 1rem;
  border: 1px solid var(--dark-color);
  color: var(--dark-color);
  border-radius: 1rem;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;

  &:hover {
    background-color: var(--dark-color);
    color: var(--light-color);
  }
}
